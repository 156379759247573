import { Box } from '@material-ui/core';
import { I3DLayer } from '../../../models/I3DLayer';
import styles from './ClassificationList.module.css';
import { useEffect, useState } from 'react';
import { I3DClassCategory, I3DClassificationClasses } from '../../../models/I3DClassification';

interface IClassificationList {
  layers: I3DLayer[];
  classificationClasses: I3DClassificationClasses[];
  onClassSelectionChange: Function;
}

export const ClassificationList = (props: IClassificationList) => {
  const { layers, classificationClasses } = props;

  const [selectedClass, setSelectedClass] = useState<I3DClassificationClasses[]>([]);

  const onClassChange = (layerName: string, catName: string) => {
    const classes = selectedClass.map((cl) => {
      if (cl.layerName === layerName) {
        let categories = cl.classificationCategories.map((cat) => {
          if (cat.categoryName === catName) {
            return { categoryName: catName, isSelected: !cat.isSelected };
          }
          return cat;
        });
        return { layerName: layerName, threeDTileLayerId: cl.threeDTileLayerId, hasChanged: true, classificationCategories: categories };
      }
      return { layerName: cl.layerName, threeDTileLayerId: cl.threeDTileLayerId, hasChanged: false, classificationCategories: cl.classificationCategories };
    });

    setSelectedClass(classes);
    props.onClassSelectionChange(classes);
  };

  useEffect(() => {
    setSelectedClass([]);

    if (classificationClasses.length > 0) {
      setSelectedClass(classificationClasses);
    } else {
      layers.forEach((layer) => {
        if (layer.classificationFile) {
          let layerName = layer.name;

          let classArr: I3DClassCategory[] = [];

          for (let i = 0; i < layer.classificationFile.classificationOverlayCategory.length; i++) {
            classArr.push({ categoryName: layer.classificationFile.classificationOverlayCategory[i].label.toString(), isSelected: layer.defaultVisibility });
          }

          setSelectedClass((oldSelectedClass) => [...oldSelectedClass, { layerName: layerName, threeDTileLayerId: layer.threeDTileLayerId, hasChanged: false, classificationCategories: classArr }]);
        }
      });
    }
  }, [layers, classificationClasses]);

  return (
    <Box>
      {layers.map(
        (layer, index) =>
          layer.classificationFile && (
            <div className={styles.classMenu} style={{ paddingTop: index > 0 ? 14 : 0 }}>
              <div>
                <label className={styles.title}>{layer.name}</label>
              </div>

              {layer.classificationFile.classificationOverlayCategory.map((cat: any) => (
                <div>
                  <input
                    type='checkbox'
                    style={{ marginTop: 6 }}
                    checked={selectedClass.find((x) => x.layerName === layer.name)?.classificationCategories.find((cc) => cc.categoryName === cat.label)?.isSelected}
                    onClick={() => onClassChange(layer.name, cat.label)}
                  />
                  <label>{cat.label}</label>
                </div>
              ))}
            </div>
          )
      )}
    </Box>
  );
};
